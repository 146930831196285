<!--

      This view allows to replace a recipe from the cart
      or to add a new recipe into the cart. It displays the
      same contents as the recipes tab.

 -->

<template>
  <Page title='Rezepte für dich' :disableSwipeBack="true" :hideback="false">
    <Recipes />
  </Page>
</template>

<script>
import Recipes from '@/components/Recipes.vue'
import Page from '@/components/Page.vue'

export default {
  name: 'discover',
  components: {
    Recipes,
    Page
  }
}
</script>
